<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <el-input v-model="queryValue" placeholder="请输入纳税人识别号"></el-input>
          <el-button @click="initTable" type="primary" class="btn">查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn">+ 新增</el-button>
        <!-- <el-button @click="addXML" class="btn" :loading='isXmlLoading'>生成XML文件</el-button> -->
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
     <comps-table :tableData="tableData" :tableHeader="tableHeader"  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="total > 0" >
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

  <renew-dialog :visible='renewVis' :cancelDialog='cancelDialog' :confirmDialog='confirmDialog' :isdeling="isdeling"/>

  <del-dialog :visible="delVis" :cancelDialog="delCancel" :confirmDialog="delConfirm" :isdeling="isdeling" :config="config">
    <div class="answer">亲，确认要删除吗？</div>
  </del-dialog>

  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import renewDialog from './dialog/Renew'
  
  export default {
    components: { compsTable,renewDialog ,delDialog },
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [  
          {prop: "unitId", label: "签章分类ID", center: 'center' ,width:'100' },
          {prop:'sealId',label:'签章详情ID',center: 'center',width:'100'},
          {prop:'caPath',label:'电子签章证书',center: 'center',},
          {prop:'qzImg',label:'电子签章图片',center: 'center',},
          {prop:'qzStatus',label:'上传状态',center: 'center',width:'130',
            render: (h, data) => {
              return (
                <span>
                  {data.row.qzImg ?  '已上传' :'未上传'}
                </span>
              );
            },
          },
          {prop:'caPwd',label:'证书密码',center: 'center',width:'130'},
          {prop:'taxId',label:'纳税人识别号',center: 'center',width:'150'},
          {prop:'createTime',label:'证书开始日期',center: 'center',},
          {prop:'endDate',label:'证书截止日期',center: 'center',},
          {prop:'createTime',label:'创建日期',center: 'center',},
          {prop:'updateTime',label:'修改日期',center: 'center',},
          {
            label: "操作",
            center: 'center',
            // fixed:"right",
            width:'200',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                    <el-button class='btn' type="text" onClick={() => { this.Renew(data.row);}}>
                      上传签章图片
                    </el-button>
                    <el-button class='btn' type="text" onClick={() => { this.delete(data.row);}}>
                      删除
                    </el-button>
                  </span>
                );
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 10,
        multipleSelection:[],

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        //当前操作行
        row:{},
        id: '',

        isXmlLoading: false, //生成XML文件按钮状态
        renewVis: false, //续费
        delVis: false,   //删除
        isdeling: false,
        companyId:'',//公司id

      }
    },
    methods: {
      add() {
        // einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
        this.$router.push('signature/add')
      },
      addXML(){
        this.isXmlLoading = true
        this.$store.dispatch("GenXml", {
          userId: this.getToken('userId'),
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'生成XML文件成功')
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isXmlLoading = false
        }).catch(err => {
          this.isXmlLoading = false
        })
      },
      edit(row) {
        this.$router.push({
          path: 'signature/edit',
          query: {
            name: row.name,
            companyRatio: row.companyRatio,
            personalRatio: row.personalRatio,
            isDefault: row.isDefault,
            id: row.id
          }
        })
      },
      
      //续费
      Renew(row) {
        this.renewVis = true;
        this.row = row;
      },
      cancelDialog(){
        this.renewVis = false;
      },
      confirmDialog(dataFile) {
        this.isdeling = true
        let data = new FormData()
        data.append('id',this.row.id)
        data.append('companyId',this.row.companyId)
        data.append('qzImg',dataFile)
        this.$store.dispatch("UploadQzImg", data).then(res => {
          if (res.success) {
            einvAlert.success("提示",res.msg)
            this.renewVis = false;
            this.initTable()
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        })
      },
      //单选删除
      delete(row) {
        this.delVis = true
        this.id = row.id,
        this.companyId =row.companyId
      },
      delCancel() { this.delVis = false },
      delConfirm() {
        this.isdeling = true
        this.$store.dispatch("DeleteSign", {
          id: this.id,
          userId: this.getToken('userId'),
          companyId : this.companyId,
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delVis = false
            this.initTable()
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        })
      },

      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      // 查询列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("FindSign", { 
          userId: this.getToken('userId'),
          taxId: this.queryValue,
          page: this.page,
          rows: this.pageSize,
          }).then(res => {
            this.isLoading = true;
            if (res.success) {
              this.isLoading = false;
              this.tableData = res.data;
              this.total = res.totalCount;

            } else {
              einvAlert.warning('提示',res.msg)
              this.isLoading = false
            }
          }).catch(err => {
            einvAlert.error("提示",err)
            this.isLoading = false
          })
      },

    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
      width: 100%;
    }
  }
  .answer {
    text-align: center;
    padding: 24px 0 14px;
    font-size: 16px;
  }
</style>
