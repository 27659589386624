<template>
    <Dialog ref="dialog" :config="config" :width='width' :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' :isdeling='isdeling' append>
        <div>
          <el-form ref="ruleForm" label-width="6rem" class="demo-ruleForm">
           <el-form-item label="电子签章图片" prop="year" required>
            <el-upload class="upload-demo" ref="upload" accept=".png,.jpg,.webp,.jpeg" action="#" 
              :on-change="handleChange" :before-upload='beforeUpload' 
              :on-preview="handlePreview" :on-remove="handleRemove" 
              drag :http-request="httpRequest"
              :file-list="fileList" :auto-upload="false" >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
             <span class="errorTips" :class="{show: message != '' }">{{message}}</span>
           </el-form-item>
          </el-form>
        </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/index";
export default {
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      config: {
        top: '14vh',
        title: '上传签章图片',
        center: true,
        btnTxt: ['保存','取消'],
      },
      width: '28rem',
      ruleForm: {
        year:'',
        name: '',
        phone:'',
      },
      fileList:[],
      fileData:'',
      message:'',
    };
  },
  methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        this.fileList = fileList.slice(-1);  //限定上传文件为一个
      },
      handleRemove(file, fileList) {
        this.fileData = '';
        this.message = '请选择上传文件';
      },
      beforeUpload(file, fileList) {
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'png'
        const extension2 = testFile === 'jpg'
        const extension3 = testFile === 'webp'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if(!extension && !extension2 && !extension3) {
          this.message = '上传文件只能是png或jpg、webp格式！'
        }else if (!isLt1M) {
          this.message = '上传Excel文件大小不能超过 1MB!'
        }else{
          this.fileData = file;
        }
      },
      handlePreview(file) {
        console.log(file);
      },
      submitGroupForm(){
        this.$refs.upload.submit();
        if (this.fileData == '') {
          this.message = '请选择上传文件';
          return;
        }
        this.confirmDialog(this.fileData) 
      },
  },
  watch:{
      message: function (val) {
        if(val){
          setTimeout(()=>{
            this.message = '';
          },1500)
        }
      },
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.7rem;
  }
  .errorTips{
    display: block;
    font-size: 12px;
    color: #f56c6c;
    margin-bottom: -15px;
    margin-top: -5px;
  }
</style>
